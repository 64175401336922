import React from "react"

// Components
import PageLayout from "../components/PageLayout"

const SecondPage = () => (
  <PageLayout seoTitle={"Expertise"}>
    <p>This wil be the expertises page</p>
  </PageLayout>
)

export default SecondPage
