import { css } from "styled-components"

// Styling
import { fonts, fontWeights } from "./fonts"
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Text styles
export default {
  headingXXL: css`
    font-family: ${fonts.primary};
    /* At 320px viewport where base is 16px multiply by an arbitrary amount to get the desired output */
    /* Ex: 16px * 2.5 = 40px */
    font-size: 40px;
    font-size: calc(var(--base-size) * 2.5);
    font-weight: ${fontWeights.bold};
    letter-spacing: -0.025em;
    line-height: 1.1em;
    text-rendering: optimizeLegibility;
    /* Only fluid sizes from here on */
    /* Starting at 96px */
    ${mq.from.XL`
      font-size: calc(var(--base-size) * 6);
    `}
  `,
  headingXL: css`
    font-family: ${fonts.primary};
    /* Ex: 16px * 2.25 = 36px */
    font-size: 36px;
    font-size: calc(var(--base-size) * 2.25);
    font-weight: ${fontWeights.bold};
    letter-spacing: -0.025em;
    line-height: 1.125em;
    /* Starting at 60px */
    ${mq.from.XL`
      font-size: calc(var(--base-size) * 3.75);
    `}
  `,
  headingL: css`
    font-family: ${fonts.primary};
    /* Ex: 16px * 2 = 32px */
    font-size: 32px;
    font-size: calc(var(--base-size) * 2);
    font-weight: ${fontWeights.bold};
    letter-spacing: -0.025em;
    line-height: 1.125em;
    /* Starting at 48px */
    ${mq.from.XL`
      font-size: calc(var(--base-size) * 3);
    `}
  `,
  headingM: css`
    font-family: ${fonts.primary};
    /* Ex: 16px * 1.75 = 28px */
    font-size: 24px;
    font-size: calc(var(--base-size) * 1.5);
    font-weight: ${fontWeights.bold};
    letter-spacing: -0.015em;
    line-height: 1.125em;
    /* Starting at 40px */
    ${mq.from.XL`
      font-size: calc(var(--base-size) * 2.5);
    `}
  `,
  headingS: css`
    font-family: ${fonts.primary};
    /* Ex: 16px * 1.5 = 24px */
    font-size: 24px;
    font-size: calc(var(--base-size) * 1.3);
    font-weight: ${fontWeights.bold};
    letter-spacing: -0.025em;
    line-height: 1.125em;
  `,
  bodyLarge: css`
    font-family: ${fonts.primary};
    /* Ex: 16px * 1 = 16px */
    font-size: 16px;
    font-size: var(--base-size);
    line-height: 1.5em;
    /* Starting at 24px */
    ${mq.from.XL`
      font-size: calc(var(--base-size) * 1.5);
    `}
  `,
  body: css`
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 1.5em;
    /* Start gradually increasing the body font size */
    ${mq.from.XS`
      font-size: calc(16px + var(--base-size) * 0.125)
    `}
    /* Only fluid font size from here on */
    ${mq.from.XL`
      font-size: var(--base-size);
    `}
  `,
  hyperlink: css`
    color: ${colors.accent1};
    text-decoration: none;
    cursor: pointer;
    span {
      position: relative;
    }
    :hover {
      text-decoration: underline;
    }
  `,
}
