import React from "react"

// Styling
import colors from "../styles/colors"

const MenuIcon: React.FC<{ color: string }> = ({
  color = colors.black,
  ...props
}) => (
  <svg viewBox="0 -53 384 384" {...props} fill={color}>
    <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0M368 277.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" />
  </svg>
)

export default MenuIcon
