import { css } from "styled-components"

// Styling
import colors from "./colors"
import textStyles from "./textStyles"
import dialog from "./dialog"

export const base = css`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  :root {
    --base-size: 16px;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    ${textStyles.body};
    background-color: ${colors.white};
    color: ${colors.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  [data-reach-dialog-content] {
    ${dialog.reachDialog}
  }

  a {
    ${textStyles.hyperlink};
  }
`

export default base
