/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import styled from "styled-components"

// Styling
import GlobalStyle from "../styles/index"
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"

// Components
import Header from "./header"
import Footer from "./Footer"

const PageLayout: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <Container>
      <Header />
      <Main>{children}</Main>
      <Footer color={colors.black} background={colors.white} />
    </Container>
  </>
)

const Container = styled.div`
  display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-template-columns: 1fr;
  grid-template-rows: min-content auto 50px;
  min-height: calc(100vh - 28px);
`

const Main = styled.main`
  grid-area: main;
  width: 90vw;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 15px;
  padding: 0 5vw;

  ${mq.from.L`
    width: 80vw;
    padding: 0 10vw;
  `}
`

export default PageLayout
