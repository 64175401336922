/**
 * Add all site colours here.
 */

export default {
  black: "#1E1E1E",
  white: "#ffffff",
  smoke: "#f3f3f3",
  lGrey: "#F6F6F6",
  accent1: "#FA4616",
  accent2: "#F6E203",
}
