// Global stylesheet
import "@reach/dialog/styles.css"
import { createGlobalStyle } from "styled-components"
import styledNormalize from "styled-normalize"

// Styling
import base from "./base"
import { fontFaces } from "./fonts"

const GlobalStyle = createGlobalStyle`
  ${fontFaces}
  ${styledNormalize}
  ${base}
`

export default GlobalStyle
