export const routes = [
  {
    link: "/",
    label: "Home",
  },
  {
    link: "/formula",
    label: "Jungle Formula",
  },
  {
    link: "/profiles/",
    label: "Function profiles",
  },
  {
    link: "/downloads",
    label: "Downloads",
  },
]

export default routes
