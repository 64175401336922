import { css } from "styled-components"

// Styling
import colors from "../styles/colors"

// Text styles
export default {
  regularSVG: css`
    fill: ${colors.black};
    width: 1em;
    height: 1em;
    vertical-align: middle;
    padding-right: 10px;
    transition: 0.3s;
  `,
}
