import React from "react"
import styled from "styled-components"

// Components
import FooterLogo from "../icons/Logo"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

interface IFooter {
  color: string
  background: string
}

const Footer = ({ color, background }: IFooter) => (
  <FooterBar background={background}>
    <FooterLogo color={color} />
  </FooterBar>
)

export default Footer

interface IFooterBar {
  background: string
}

const FooterBar = styled.footer<IFooterBar>`
  background-color: ${props =>
    props.background ? props.background : colors.white};
  padding: 0 3em;
  line-height: 1;
  height: min-content;
  text-align: center;

  svg {
    width: 90%;
    padding: 50px 0;
    text-align: center;
    margin: 0 auto;

    ${mq.from.S`
      width: 60%;
    `}

    ${mq.from.M`
      width: 50%;
    `}

    ${mq.from.L`
      width: 30%;
    `}
  }
`
