import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"

interface IProps {
  className?: string
  theme?: "dark" | "light"
  size?: number
}

const Logo = ({ className, theme = "dark", size = 200 }: IProps) => (
  <Wrapper
    className={className}
    viewBox="0 0 1607 805"
    size={size}
    fill={theme === "dark" ? colors.black : colors.white}
  >
    <g fillRule="evenodd">
      <path d="M268.497945,268 L268.497945,536.497945 L0,536.497945 L0,805 L268.497945,805 C416.787651,805 537,684.787651 537,536.497945 L537,268 L268.497945,268 Z" />
      <path d="M804,537 L804,268.502055 L537,268.502055 L537,0 L804,0 C951.4624,0 1071,120.212349 1071,268.502055 L1071,537 L804,537 Z" />
      <path d="M1338.50205,537 L1338.50205,268.502055 L1070,268.502055 L1070,0 L1338.50205,0 C1486.78765,0 1607,120.212349 1607,268.502055 L1607,537 L1338.50205,537 Z" />
    </g>
  </Wrapper>
)

interface IWrapperProps {
  size: number
}

const Wrapper = styled.svg`
  width: ${(props: IWrapperProps) => props.size}px;
`

export default Logo
