import React, { useState, useRef, useLayoutEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import svgStyles from "../styles/svgStyles"
import { mq } from "../styles/utils/mq"

// Components
import Logo from "./Logo"
import MenuIcon from "../icons/MenuIcon"

// Data
import routes from "../static/data"

const Header: React.FC = () => {
  const [menuOpen, ToggleMenu] = useState(false)
  const [animateHeight, setNewHeight] = useState(0)
  const navRef = useRef<HTMLDivElement>(null)

  const ToggleMobileMenu = () => {
    if (
      navRef.current !== null &&
      navRef.current.firstElementChild !== null &&
      navRef.current.firstElementChild.clientHeight !== undefined
    ) {
      // Toggle the mobile navigation to open / close
      ToggleMenu(prevState => !prevState)

      // Get the height and store it in the state this way it will get animated
      setNewHeight(navRef.current.firstElementChild.clientHeight)
    }
  }

  const updateSize = () => {
    // Close all the opened containers
    setNewHeight(0)
    // Make sure the labels are reset
    ToggleMenu(false)
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  const activeRoute = {
    color: colors.accent1,
  }

  return (
    <Container>
      <Wrapper>
        <Title>
          <Link to={"/"}>
            <Logo size={100} />
          </Link>
        </Title>
        <MainNav>
          <ul>
            {routes.map(route => (
              <NavTab key={route.label}>
                <Link to={route.link + "/"} activeStyle={activeRoute}>
                  {route.label}
                </Link>
              </NavTab>
            ))}
          </ul>
        </MainNav>
        <IconHolder onClick={ToggleMobileMenu}>
          <MenuIcon color={colors.black} />
        </IconHolder>
      </Wrapper>

      <MobileNav ref={navRef} isOpen={menuOpen} animateHeight={animateHeight}>
        <ul>
          {routes.map(route => (
            <NavTab key={route.label}>
              <Link to={route.link}>{route.label}</Link>
            </NavTab>
          ))}
        </ul>
      </MobileNav>
    </Container>
  )
}

const Container = styled.header``

const IconHolder = styled.div`
  cursor: pointer;
  width: fit-content;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0 auto;
  max-width: 960;
  padding: 1.45rem 1.0875rem;

  ${IconHolder} svg {
    ${svgStyles.regularSVG};
    position: absolute;
    font-size: 2em;
    right: 1em;
    top: 1em;
    cursor: pointer;

    &:hover {
      fill: ${colors.accent1};
    }

    ${mq.from.L`
      display: none;
    `}
  }
`

const NavTab = styled.li`
  position: relative;

  a {
    transition: 0.3s;
    color: ${colors.black};
  }
`

const Title = styled.h1`
  display: flex;
  margin: 0;
  align-items: center;

  a {
    display: inline-flex;
    margin-right: 0.5em;
    padding-top: 0.25em;
  }
`

const MainNav = styled.nav`
  display: none;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul li {
    margin: 1em;
    a {
      text-transform: capitalize;
      ${textStyles.headingS};
      cursor: pointer;

      :hover {
        color: ${colors.accent1};
        text-decoration: none;
      }
    }
  }

  ${mq.from.L`
    display: block;
  `}
`

const MobileNav = styled(MainNav)<{ isOpen: boolean; animateHeight: number }>`
  display: block;
  transition: 1s;
  height: 100%;
  overflow: hidden;
  ${({ isOpen, animateHeight }) =>
    isOpen ? `max-height: ${animateHeight}px;` : `max-height: 0;`}

  ${mq.from.L`
    display: none;
  `}

  ul {
    margin-top: -18px;
    display: block;
    height: 100%;
    li {
      margin: 1em;
      width: fit-content;
    }
  }
`

export default Header
