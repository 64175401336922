import React from "react"

// Components
import Layout from "../components/Layout"
import SEO from "../components/seo"

interface IPageLayoutProps {
  seoTitle: string
  children: React.ReactNode
}

const PageLayout: React.FunctionComponent<IPageLayoutProps> = ({
  seoTitle,
  children,
}: IPageLayoutProps) => (
  <Layout>
    <SEO title={seoTitle} />
    {children}
  </Layout>
)

export default PageLayout
